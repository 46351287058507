import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-241ac720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_div" }
const _hoisted_2 = { style: {"min-width":"2000px","width":"100%"} }
const _hoisted_3 = { class: "content-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_DashBoard = _resolveComponent("DashBoard")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Category = _resolveComponent("Category")!
  const _component_Feedback = _resolveComponent("Feedback")!
  const _component_Member = _resolveComponent("Member")!
  const _component_Notice = _resolveComponent("Notice")!
  const _component_Admin = _resolveComponent("Admin")!
  const _component_ElevenLabs = _resolveComponent("ElevenLabs")!
  const _component_PromotionCode = _resolveComponent("PromotionCode")!
  const _component_Coupon = _resolveComponent("Coupon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_Loading, null, null, 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _createVNode(_component_TopBar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SideBar, { onChangeSelectedHomeView: _ctx.changeSelectedHomeView }, null, 8, ["onChangeSelectedHomeView"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.DashBoard)
          ? (_openBlock(), _createBlock(_component_DashBoard, {
              key: 0,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Content)
          ? (_openBlock(), _createBlock(_component_Content, {
              key: 1,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Category)
          ? (_openBlock(), _createBlock(_component_Category, {
              key: 2,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Feedback)
          ? (_openBlock(), _createBlock(_component_Feedback, {
              key: 3,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Member)
          ? (_openBlock(), _createBlock(_component_Member, {
              key: 4,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Notice)
          ? (_openBlock(), _createBlock(_component_Notice, {
              key: 5,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Admin)
          ? (_openBlock(), _createBlock(_component_Admin, {
              key: 6,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.ElevenLabs)
          ? (_openBlock(), _createBlock(_component_ElevenLabs, {
              key: 7,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.PromotionCode)
          ? (_openBlock(), _createBlock(_component_PromotionCode, {
              key: 8,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true),
        (_ctx.selectedHomeViewConstant == _ctx.homeViewConstant.Coupon)
          ? (_openBlock(), _createBlock(_component_Coupon, {
              key: 9,
              onChangeLoadingStatus: _ctx.changeLoadingStatus
            }, null, 8, ["onChangeLoadingStatus"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}