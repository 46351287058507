<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space style="width: 1700px; min-width: 1700px; height: auto">
        <NCard
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1000px;
            min-width: 1000px;
            height: 100%;
          "
        >
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Section</div>
            <n-radio-group
              v-model:value="depth1Value"
              name="depth1Value"
              @change="selectSection"
            >
              <n-radio
                v-for="d1 in depth1"
                :key="d1.key"
                :value="d1.key"
                :label="d1.key"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
        </NCard>
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 30px;
            width: 550px;
            min-width: 550px;
            height: 100%;
          "
        >
          <n-space style="height: 100%; flex-direction: column" justify="end" align="end">
            <NButton
              style="width: 200px; height: 30px; border: none"
              v-on:click="showAddCategory1"
            >
              Category 1 추가
            </NButton>
            <NButton
              style="width: 200px; height: 30px; border: none"
              v-on:click="showEditOrder"
            >
              순서 변경
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>

    <div style="margin-top: 50px">
      <n-data-table
        style="margin-left: 100px; width: 1600px; min-width: 1600px"
        ref="table"
        :columns="columns"
        :data="showDepth2List"
        :pagination="pagination"
        :max-height="520"
        striped
        :row-props="rowProps"
        :row-class-name="rowClassName"
      />
    </div>

    <div style="margin-top: 50px" v-if="isShowDepth3Table">
      <n-space style="height: 100%" justify="end" align="end">
        <NButton
          style="width: 200px; height: 30px; border: none"
          v-on:click="showAddCategory2"
        >
          Category 2 추가
        </NButton>
      </n-space>
      <n-data-table
        style="margin-left: 100px; margin-top: 50px; width: 1600px; min-width: 1600px"
        ref="table"
        :columns="columns2"
        :data="showDepth3List"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>

  <NModal
    v-model:show="isAlert"
    :mask-closable="false"
    :content="alertMessage"
    :type="alertType"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
  </NModal>

  <NModal
    v-model:show="isShowEtcViewDialog"
    :mask-closable="false"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
    <template v-if="alertType === 'errorE'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <n-card :bordered="false" role="dialog" aria-modal="true">
        <div>
          <p v-for="(value, key) in etcDetails" :key="key">{{ key }}: {{ value }}</p>
        </div>
      </n-card>
    </template>
  </NModal>

  <NModal
    v-model:show="isShowSamplePlayDialog"
    :mask-closable="false"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClickShowSample"
    @close="closeShowSample"
  >
    <template v-if="alertType === 'errorS'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <n-card :bordered="false" role="dialog" aria-modal="true">
        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-spinner"></div>
        </div>
        <p v-if="Object.keys(sampleObj).length === 0">등록된 샘플이 없습니다.</p>

        <div v-for="(value, key) in sampleObj" :key="key">
          <div style="margin-bottom: 20px">
            <p style="font-size: 20px; margin: 0">{{ key }}</p>
            <div class="title_delimiter_line"></div>
            <p>설명 : {{ value.description }}</p>

            <p v-if="value.sample1Url">
              Sample 1:
              <span
                ><NButton
                  strong
                  secondary
                  circle
                  type="primary"
                  @click="playStopFile(value, sampleType.SAMPLE_1)"
                  ><template #icon>
                    <n-icon v-if="!value.isPlay1"> <PlayIcon /> </n-icon>
                    <n-icon v-if="value.isPlay1">
                      <StopIcon /> </n-icon></template></NButton
              ></span>
            </p>

            <p v-if="value.sample2Url">
              Sample 2:<span
                ><NButton
                  strong
                  secondary
                  circle
                  type="primary"
                  @click="playStopFile(value, sampleType.SAMPLE_2)"
                  ><template #icon>
                    <n-icon v-if="!value.isPlay2"> <PlayIcon /> </n-icon>
                    <n-icon v-if="value.isPlay2">
                      <StopIcon /> </n-icon></template></NButton
              ></span>
            </p>
          </div>
        </div>
      </n-card>
    </template>
  </NModal>

  <NModal
    v-model:show="isShowManageCategoryDialog"
    :mask-closable="false"
    preset="dialog"
    title="카테고리 관리"
    :positive-text="existingCategoryData ? '수정' : '등록'"
    @positive-click="onPositiveClickManageCategory"
    style="width: 700px"
    @close="closeManageCategory"
  >
    <template v-if="alertType === 'errorC'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <n-card :bordered="false" role="dialog" aria-modal="true">
        <div v-if="isLoading || isFetching" class="loading-overlay">
          <div class="loading-spinner"></div>
        </div>
        <NCard style="height: 100%">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Section</div>
            <n-radio-group
              v-model:value="depth1Value"
              name="depth1Value"
              v-bind:disabled="true"
            >
              <n-radio
                v-for="d1 in depth1"
                :key="d1.key"
                :value="d1.key"
                :label="d1.key"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Category 1</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="newDepth2Value"
              style="height: 30px; text-align: left"
              maxlength="2"
              :loading="isCheckingDuplicate"
              :disabled="
                isDepth3 ? true : isCheckingDuplicate || existingCategoryData != undefined
              "
            />

            <NButton
              style="height: 30px"
              v-on:click="checkDuplicate"
              :type="isDepth2Confirmed ? 'primary' : 'defauult'"
              v-if="existingCategoryData == undefined && !isDepth3"
            >
              {{ isDepth2Confirmed ? "중복 확인 완료" : "중복 확인" }}
            </NButton>
          </n-space>
          <div class="add_content_delimiter_line"></div>

          <n-space item-style="display: flex;" v-if="!isDepth3">
            <div style="font-size: 20px; width: 150px; font-weight: bold">사용 여부</div>
            <n-radio-group v-model:value="useCategoryValue" name="isUse">
              <n-radio
                v-for="ct in useCategory"
                :key="ct.value"
                :value="ct.value"
                :label="ct.label"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line" v-if="!isDepth3"></div>

          <n-space item-style="display: flex;" v-if="!isDepth3">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              무료 콘텐츠
            </div>
            <n-radio-group v-model:value="freeCategoryValue" name="isFree">
              <n-radio
                v-for="ct in useCategory"
                :key="ct.value"
                :value="ct.value"
                :label="ct.label"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line" v-if="!isDepth3"></div>
          <!-- <n-space item-style="display: flex;" v-if="!isDepth3">
            <div style="font-size: 20px; width: 150px; font-weight: bold">필수 여부</div>
            <n-radio-group v-model:value="mandatoryCategoryValue" name="isMandatory">
              <n-radio
                v-for="ct in mandatoryCategory"
                :key="ct.value"
                :value="ct.value"
                :label="ct.label"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line" v-if="!isDepth3"></div> -->
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">한글 이름</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="storyKor"
              style="height: 30px; text-align: left"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">영어 이름</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="storyEng"
              style="height: 30px; text-align: left"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">기타 언어</div>

            <NButton style="height: 30px" v-on:click="showAddStoryEtc"> 추가 </NButton>
          </n-space>
          <n-space item-style="display: flex;margin-top: 30px" align="center">
            <div v-for="(value, key) in newEtcDetails" :key="key">
              <n-space item-style="display: flex;" align="center">
                <NInput
                  type="text"
                  :value="key"
                  style="height: 30px; text-align: left"
                  :disabled="true"
                />
                <NInput
                  type="text"
                  v-model:value="newEtcDetails[key]"
                  style="height: 30px; text-align: left"
                  v-bind:disabled="editEtcKey != key"
                />
                <NButton
                  style="height: 30px"
                  v-on:click="editStoryEtcFromList(key)"
                  type="warning"
                >
                  {{ editEtcKey != key ? "수정" : "수정 확인" }}
                </NButton>
                <NButton
                  style="height: 30px"
                  v-on:click="delStoryEtcFromList(key)"
                  type="error"
                >
                  삭제
                </NButton>
              </n-space>
            </div>
          </n-space>

          <div class="add_content_delimiter_line" v-if="!isDepth3"></div>
          <n-space item-style="display: flex;" v-if="!isDepth3">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Sample</div>
            <NButton style="height: 30px" v-on:click="showAddStorySample(false)">
              추가
            </NButton>
          </n-space>

          <n-space item-style="display: flex;margin-top: 30px" align="center">
            <div v-for="(value, key) in newSampleDetails" :key="key">
              <n-space item-style="display: flex;" align="center">
                <NInput
                  type="text"
                  :value="key"
                  style="height: 30px; width: 70px; text-align: left"
                  :disabled="true"
                />
                <NInput
                  type="text"
                  :value="value.description ?? ''"
                  style="height: 30px; text-align: left"
                  :disabled="true"
                />
                <div class="flex-col-mid">
                  <p class="no-margin-p">샘플 1</p>
                  <n-icon size="20" v-if="value.sample1.file || value.sample1.sample1Url">
                    <CheckIcon />
                  </n-icon>
                  <n-icon size="20" v-else>
                    <CloseIcon />
                  </n-icon>
                </div>
                <div>
                  <p class="no-margin-p">샘플 2</p>
                  <n-icon size="20" v-if="value.sample2.file || value.sample2.sample2Url">
                    <CheckIcon />
                  </n-icon>
                  <n-icon size="20" v-else="!value.sample2">
                    <CloseIcon />
                  </n-icon>
                </div>
                <NButton
                  style="height: 30px"
                  v-on:click="editStorySample(key)"
                  type="warning"
                  v-if="isShowManageCategoryDialog"
                >
                  수정
                </NButton>
                <NButton
                  style="height: 30px"
                  v-on:click="delStorySampleFromList(key)"
                  type="error"
                >
                  삭제
                </NButton>
              </n-space>
            </div>
          </n-space>
        </NCard>
      </n-card>
    </template>
  </NModal>

  <NModal
    v-model:show="isShowAddEtcDialog"
    :mask-closable="false"
    preset="dialog"
    title="기타 언어"
    style="width: 600px; position: fixed; right: 100px; bottom: 500px"
  >
    <n-space item-style="display: flex;margin-top: 20px" align="center">
      <n-select
        style="font-size: 15px; width: 200px"
        v-model:value="countryValue"
        :options="countryValues"
        placeholder="나라를 선택해주세요"
      />
      <NInput
        type="text"
        placeholder="스토리를 입력해주세요"
        v-model:value="storyEtcValue"
        style="height: 30px; text-align: left"
      />
      <NButton style="height: 30px" v-on:click="addStoryEtcToList"> 추가 </NButton>
    </n-space>

    <n-space item-style="display: flex;margin-top: 20px" align="center">
      <p v-if="Object.keys(newEtcDetails).length === 0">
        추가된 기타 언어가 아직 없습니다.
      </p>
      <div v-for="(value, key) in newEtcDetails" :key="key">
        <n-space item-style="display: flex;" align="center">
          <NInput
            type="text"
            :value="key"
            style="height: 30px; text-align: left"
            :disabled="true"
          />
          <NInput
            type="text"
            v-model:value="newEtcDetails[key]"
            style="height: 30px; text-align: left"
            v-bind:disabled="editEtcKey == undefined"
          />
          <NButton
            style="height: 30px"
            v-on:click="editStoryEtcFromList(key)"
            type="warning"
          >
            {{ editEtcKey == undefined ? "수정" : "수정 확인" }}
          </NButton>
          <NButton
            style="height: 30px"
            v-on:click="delStoryEtcFromList(key)"
            type="error"
          >
            삭제
          </NButton>
        </n-space>
      </div>
    </n-space>

    <template #action>
      <NButton style="height: 30px" v-on:click="closeAndAddEtc" type="success">
        확인
      </NButton>
    </template>
  </NModal>

  <NModal
    v-model:show="isShowAddSampleDialog"
    :mask-closable="false"
    preset="dialog"
    title="샘플 추가"
    style="width: 600px; position: fixed; right: 100px; bottom: 200px"
  >
    <n-space item-style="display: flex;margin-top: 20px" align="center">
      <div style="font-size: 20px; width: 150px; font-weight: bold">언어</div>
      <n-select
        style="font-size: 15px; width: 200px"
        v-model:value="sampleCountryValue"
        :options="sampleCountryValues"
        placeholder="나라를 선택해주세요"
        :disabled="isEditStorySample"
    /></n-space>

    <n-space item-style="display: flex; margin-top: 15px;" align="center">
      <div style="font-size: 20px; width: 150px; font-weight: bold">설명</div>
      <NInput
        type="textarea"
        placeholder=""
        v-model:value="sampleDesc"
        style="height: 200px; width: 300px; text-align: left"
      />
    </n-space>

    <n-space align="start" item-style="display: flex;   margin-top: 15px;">
      <div style="font-size: 20px; width: 150px; font-weight: bold">샘플 1</div>
      <n-upload
        v-model:file-list="sample1FileList"
        @change="sample1Select"
        ref="uploadS1Ref"
        style="width: 300px"
        :default-upload="false"
        max="1"
        v-bind:disabled="!sampleCountryValue || isDisableUpload"
      >
        <n-button>Select File</n-button>
      </n-upload>
    </n-space>

    <n-space
      align="start"
      item-style="display: flex; "
      v-if="existingCategoryData && newSampleDetails[sampleCountryValue]"
    >
      <div
        style="font-size: 20px; width: 150px; font-weight: bold"
        v-if="newSampleDetails[sampleCountryValue].sample1.sample1Url"
      >
        현재 저장 파일
      </div>

      <div style="width: 250px">
        {{ newSampleDetails[sampleCountryValue].sample1.sample1Url ?? "" }}
      </div>
    </n-space>

    <n-space align="start" item-style="display: flex;   margin-top: 15px;">
      <div style="font-size: 20px; width: 150px; font-weight: bold">샘플 2</div>
      <n-upload
        v-model:file-list="sample2FileList"
        @change="sample2Select"
        ref="uploadS2Ref"
        style="width: 300px"
        :default-upload="false"
        max="1"
        v-bind:disabled="!sampleCountryValue || isDisableUpload"
      >
        <n-button>Select File</n-button>
      </n-upload>
    </n-space>

    <n-space
      align="start"
      item-style="display: flex; "
      v-if="existingCategoryData && newSampleDetails[sampleCountryValue]"
    >
      <div
        style="font-size: 20px; width: 150px; font-weight: bold"
        v-if="newSampleDetails[sampleCountryValue].sample2.sample2Url"
      >
        현재 저장 파일
      </div>
      <div style="width: 250px">
        {{ newSampleDetails[sampleCountryValue].sample2.sample2Url ?? "" }}
      </div>
    </n-space>

    <n-space
      align="start"
      style="margin-top: 10px"
      v-if="existingCategoryData && newSampleDetails[sampleCountryValue]"
    >
      <div style="font-size: 20px; width: 150px; font-weight: bold">파일 재등록</div>
      <NButton style="width: 100px; height: 30px; border: none" v-on:click="fileReUpload">
        파일 재등록
      </NButton>
    </n-space>

    <template #action>
      <NButton style="height: 30px" v-on:click="closeAndAddSample" type="success">
        {{ !isEditStorySample ? "추가" : "수정" }}
      </NButton>
    </template>
  </NModal>

  <NModal
    v-model:show="isDeleteConfirm"
    :mask-closable="false"
    :content="deleteConfirmMessage"
    :type="deleteConfirmType"
    preset="dialog"
    negative-text="취소"
    positive-text="확인"
    @positive-click="onDeletePositiveClick"
    @negative-click="onDeleteNegativeClick"
  >
  </NModal>

  <NModal
    v-model:show="isShowEditOrder"
    :mask-closable="false"
    preset="dialog"
    title="순서 변경"
    positive-text="변경"
    negative-text="취소"
    @positive-click="onPositiveClickEditOrder"
    @negative-click="onNegativeClickEditOrder"
    @close="closeEditOrder"
    style="width: 700px"
  >
    <template v-if="alertType === 'errorE'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <table cellspacing="0" style="margin-top: 20px">
        <tr class="header-row">
          <td style="width: 40px">순서</td>
          <td style="width: 130px">Category 1</td>
          <td style="width: 200px">한글 이름</td>
          <td style="width: 180px">영어 이름</td>
          <td style="width: 60px">무료 여부</td>
        </tr>
      </table>

      <div style="max-height: 400px; overflow: auto">
        <div style="display: flex">
          <table cellspacing="0" style="margin-top: 20px">
            <tr v-for="element in tempShowDepth2List" :key="element.SK">
              <td style="width: 60px">
                <div style="display: flex; align-items: center">
                  <NInputNumber
                    placeholder=""
                    v-model:value="element.order"
                    style="text-align: left; margin: 0px; padding-left: 0px"
                    :disabled="enabledEditCat !== element.SK"
                    :show-button="false"
                    @keyup.enter="enableEditOrder(element)"
                    :ref="element.SK"
                  />
                  <NButton
                    strong
                    quaternary
                    circle
                    type="primary"
                    size="tiny"
                    @click="enableEditOrder(element)"
                    v-if="enabledEditCat !== element.SK"
                    >수정</NButton
                  >
                  <NButton
                    strong
                    quaternary
                    circle
                    type="warning"
                    size="tiny"
                    @click="enableEditOrder(element)"
                    v-if="enabledEditCat === element.SK"
                    >완료</NButton
                  >
                </div>
              </td>
            </tr>
          </table>
          <table cellspacing="0" style="margin-top: 20px">
            <draggable
              v-model="tempShowDepth2List"
              item-key="sk"
              @end="endDrag"
              ghost-class="ghost"
            >
              <template #item="{ element }">
                <tr class="body-row" :id="element.order">
                  <td style="width: 130px">{{ element.depth2 }}</td>
                  <td style="width: 200px">{{ element.storyKor }}</td>
                  <td style="width: 180px">{{ element.storyEng }}</td>
                  <td style="width: 40px">{{ element.isFreeParse }}</td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>

      <!-- <div style="max-height: 400px; overflow: auto">
        <table cellspacing="0" style="margin-top: 20px">
          <draggable
            v-model="tempShowDepth2List"
            item-key="sk"
            @end="endDrag"
            ghost-class="ghost"
          >
            <template #item="{ element }">
              <tr class="body-row" :id="element.order">
                <td style="width: 60px">
                  <div style="display: flex; align-items: center">
                    <NInputNumber
                      placeholder=""
                      v-model:value="element.order"
                      style="text-align: left; margin: 0px; padding-left: 0px"
                      :disabled="enabledEditCat !== element.SK"
                      :show-button="false"
                      @keyup.enter="enableEditOrder(element)"
                      :ref="element.SK"
                    />
                    <NButton
                      strong
                      quaternary
                      circle
                      type="primary"
                      size="tiny"
                      @click="enableEditOrder(element)"
                      v-if="enabledEditCat !== element.SK"
                      >수정</NButton
                    >
                    <NButton
                      strong
                      quaternary
                      circle
                      type="warning"
                      size="tiny"
                      @click="enableEditOrder(element)"
                      v-if="enabledEditCat === element.SK"
                      >완료</NButton
                    >
                  </div>
                </td>
                <td style="width: 130px">{{ element.depth2 }}</td>
                <td style="width: 200px">{{ element.storyKor }}</td>
                <td style="width: 180px">{{ element.storyEng }}</td>
                <td style="width: 40px">{{ element.isFreeParse }}</td>
              </tr>
            </template>
          </draggable>
        </table>
      </div> -->

      <!-- <draggable v-model="showDepth2List" item-key="sk" @end="endDrag()">
        <template #item="{ element }">
          <div
            style="
              margin-bottom: 10px;
              padding: 5px;
              border: solid 1px gray;
              border-radius: 30pt;
              align-items: center;
              justify-content: center;
            "
            class="dragItem"
          >
            <div>{{ element.depth2 }}</div>
          </div>
        </template>
      </draggable> -->
    </template>
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NIcon,
  NModal,
  NRadio,
  NRadioGroup,
  NInput,
  NUpload,
  UploadInst,
  UploadFileInfo,
  NInputNumber,
  InputInst,
} from "naive-ui";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import {
  LogOutOutline as LogOutIcon,
  Play as PlayIcon,
  Stop as StopIcon,
  Close as CloseIcon,
  Checkmark as CheckIcon,
  EllipsisVerticalSharp as DragIcon,
  Pencil as EditIcon,
} from "@vicons/ionicons5";

import Draggable from "vuedraggable";
const getContents = getCallable("getContents");
const getStorys = getCallable("getStorysForAdmin");
const getSample = getCallable("getSample");
const existStory = getCallable("existStory");
const addStory = getCallable("addStory");
const modifyStory = getCallable("modifyStory");
const modifySample = getCallable("modifySample");
const deleteStory = getCallable("deleteStory");
const modifyStoryOrder = getCallable("modifyStoryOrder");

export default defineComponent({
  name: "Category",
  emits: ["changeLoadingStatus"],
  components: {
    NDataTable,
    NCheckbox,
    NSpace,
    NInput,
    NCard,
    NSelect,
    NButton,
    NIcon,
    NModal,
    NRadio,
    NRadioGroup,
    PlayIcon,
    StopIcon,
    CloseIcon,
    CheckIcon,
    Loading,
    NUpload,
    Draggable,
    DragIcon,
    EditIcon,
    NInputNumber,
  },
  data() {
    return {
      contentTitle: "",
      isAlert: false,
      alertMessage: "",
      alertType: "",
      informalSpeech: false,
      honorific: false,
      pagination: { pageSize: 100 },
      countryValues: [
        {
          label: "스페인",
          value: "es_ES",
        },
        {
          label: "일본",
          value: "ja_JP",
        },
      ],
      sampleCountryValues: [] as any,
      sampleCountryValue: null as any,
      countryValue: null,

      columns2: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "Category 1",
          key: "depth2",
          sorter: "default",
        },
        {
          title: "Category 2",
          key: "depth3",
          sorter: "default",
        },
        {
          title: "한글 이름",
          key: "storyKor",
          sorter: "default",
        },
        {
          title: "영어 이름",
          key: "storyEng",
          sorter: "default",
        },
        {
          title: "Story Etc",

          render(row: any) {
            return row.storyEtc != undefined &&
              row.storyEtc &&
              Object.keys(row.storyEtc).length != 0
              ? h(
                  NButton,
                  {
                    size: "small",
                    onClick: () => {
                      row.rowOnClickEtc(row);
                    },
                    // bordered: false,
                  },
                  { default: () => "상세" }
                )
              : null;
          },
        },

        {
          title: "수정",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClickModify2(row);
                },
                // bordered: false,
              },
              { default: () => "수정" }
            );
          },
        },
        {
          title: "삭제",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClickDelete2(row);
                },
                // bordered: false,
              },
              { default: () => "삭제" }
            );
          },
        },
      ],

      columns: [
        {
          title: "순서",
          key: "order",
          defaultSortOrder: "ascend",
          sorter: "default",
        },
        {
          title: "Category 1",
          key: "depth2",
          sorter: "default",
        },
        {
          title: "한글 이름",
          key: "storyKor",
          sorter: "default",
        },
        {
          title: "영어 이름",
          key: "storyEng",
          sorter: "default",
        },
        {
          title: "Story Etc",

          render(row: any) {
            return row.storyEtc != undefined &&
              row.storyEtc &&
              Object.keys(row.storyEtc).length != 0
              ? h(
                  NButton,
                  {
                    size: "small",
                    onClick: () => {
                      row.rowOnClickEtc(row);
                    },
                    // bordered: false,
                  },
                  { default: () => "상세" }
                )
              : null;
          },
        },
        {
          title: "사용 여부",
          key: "isUseParse",
          sorter: "default",
        },
        {
          title: "무료 콘텐츠",
          key: "isFreeParse",
          sorter: "default",
        },
        {
          title: "미리듣기",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClickSample(row);
                },
                // bordered: false,
              },
              { default: () => "확인" }
            );
          },
        },

        {
          title: "수정",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClickModify1(row);
                },
                // bordered: false,
              },
              { default: () => "수정" }
            );
          },
        },
        {
          title: "삭제",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClickDelete1(row);
                },
                // bordered: false,
              },
              { default: () => "삭제" }
            );
          },
        },
      ],
      renderCell: (value: any) => {
        return h(Draggable);
      },
      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 },
      ],
      baseList: [] as any,
      showDepth2List: [] as any,
      tempShowDepth2List: [] as any,
      showDepth3List: [] as any,
      storyList: [] as any,
      depth1: [] as any,
      depth2: [] as any,
      depth3: [] as any,
      depth1Value: common.Section1 as any,
      depth2Value: undefined,
      depth3Value: undefined,
      isShowManageCategoryDialog: false as boolean,
      selectedItem: undefined as any,
      audio: undefined as any,
      isShowEtcViewDialog: false,
      etcDetails: {} as any,
      sampleObj: {} as any,
      isShowSamplePlayDialog: false,
      isShowEditOrder: false,
      sampleType: constant.sample_type,
      isLoading: false,
      newDepth2Value: "",
      useCategory: [
        { label: "예", value: true },
        { label: "아니요", value: false },
      ] as any,
      mandatoryCategory: [
        { label: "예", value: true },
        { label: "아니요", value: false },
      ] as any,
      isShowAddEtcDialog: false,
      storyEtcValue: "",
      newEtcDetails: {} as any,
      isShowAddSampleDialog: false,
      storyKor: "",
      storyEng: "",
      sampleDesc: "",
      uploadS1Ref: null as UploadInst | null,
      uploadS2Ref: null as UploadInst | null,
      sample1FileList: [] as UploadFileInfo[],
      sample2FileList: [] as UploadFileInfo[],
      file1: undefined as any,
      file2: undefined as any,
      newSampleDetails: {} as any,
      addSampleDetails: {} as any,
      tempSampleDetails: {} as any,
      useCategoryValue: null as any,
      mandatoryCategoryValue: null as any,
      freeCategoryValue: null as any,
      editEtcKey: undefined as any,
      isEditStorySample: false,
      isCheckingDuplicate: false,
      isDepth2Confirmed: false,
      existingCategoryData: undefined as any,
      isDisableUpload: false,
      isShowDepth3Table: false,
      isDepth3: false,
      selectedRowIndex: undefined as any,
      isDeleteConfirm: false,
      deleteConfirmMessage: "",
      deleteConfirmType: "error",
      sItem: undefined as any,
      isFetching: false,
      orderValue: undefined as any,
      enabledEditCat: null as any,
      currOrder: undefined as any,
      inputInstRef: null as InputInst | null,
    };
  },
  // computed: {
  //   computedValue: {
  //     get(): any {
  //       return this.isDepth3 ? this.depth2Value : this.newDepth2Value;
  //     },
  //     set(value: any) {
  //       if (this.isDepth3) {
  //         this.depth2Value = value;
  //       } else {
  //         this.newDepth2Value = value;
  //       }
  //     },
  //   },
  // },
  watch: {
    newDepth2Value(newVal) {
      // Automatically convert the input value to uppercase
      this.newDepth2Value = newVal.toUpperCase();
      if (this.isDepth2Confirmed) {
        this.isDepth2Confirmed = false;
      }
    },
  },
  setup() {
    const tableRef = ref(null);

    return {};
  },
  beforeUnmount() {
    this.pauseAudio();
  },
  mounted() {
    this.fetchData();

    // this.useCategoryValue = this.useCategory[0].value;
    // this.mandatoryCategoryValue = this.mandatoryCategory[0].value;
  },
  methods: {
    async fetchData() {
      this.$emit("changeLoadingStatus", true);
      try {
        await Promise.all([this.getStorys()]);
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("changeLoadingStatus", false);
      }
    },
    pauseAudio() {
      if (this.audio != undefined) this.audio.pause();
    },
    selectItem(row: any) {
      this.selectedItem = row;
    },
    showEtcDetail(row: any) {
      this.etcDetails = row.storyEtc;
      this.isShowEtcViewDialog = true;
    },
    showSampleDetail(row: any) {
      this.getStorySample(row.SK);
    },
    setCategoryData1(row: any) {
      this.setCategoryData(row);
      this.isDepth3 = false;
    },
    setCategoryData2(row: any) {
      this.setCategoryData(row);
      this.isDepth3 = true;
    },
    setCategoryData(row: any) {
      this.existingCategoryData = row;
      this.showManageCategoryDialog();
    },
    confirmDelete1(row: any) {
      this.confirmDelete(row, false);
    },
    confirmDelete2(row: any) {
      this.confirmDelete(row, true);
    },
    confirmDelete(row: any, isDepth3: boolean) {
      if (!isDepth3) {
        if (this.hasDepth3(this.storyList, row.depth2)) {
          this.alertMessage = "해당 카테고리를 삭제할 수 없습니다.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
      }

      let depth2 = row.depth2;
      let depth3 = "";
      if (isDepth3) {
        depth3 = row.depth3;
      }

      let msg = depth2 + "-" + depth3 + "를 삭제 하시겠습니까?";
      if (depth3 === "") {
        msg = depth2 + "를 삭제 하시겠습니까?";
      }

      this.deleteConfirmMessage = msg;
      this.isDeleteConfirm = true;
      this.sItem = row;
    },
    hasDepth3(items: any, depth2Value: any) {
      return items.some(
        (item: any) =>
          item.depth2 === depth2Value &&
          item.depth1 === this.depth1Value &&
          item.hasOwnProperty("depth3")
      );
    },

    showAddStoryEtc() {
      this.isShowAddEtcDialog = true;
      this.resetEtc();
    },
    showAddStorySample(isEdit: boolean) {
      this.createCountryCodeList();
      if (!isEdit) {
        this.resetSample();
      }
    },
    selectSection() {
      this.showDepth2List = [];

      let index = 0;
      for (let item of this.storyList) {
        if (item.depth1 == this.depth1Value && item.depth3 == undefined) {
          this.showDepth2List.push(item);
          item.index = index;
          index += 1;
        }
      }

      this.showDepth2List.sort((a: any, b: any) => a.order - b.order);

      this.tempShowDepth2List = JSON.parse(JSON.stringify(this.showDepth2List));

      this.isShowDepth3Table = false;
      this.selectedRowIndex = undefined;
      // this.selectCategory1();
    },
    selectCategory1() {
      this.showDepth3List = [];
      let index = 0;

      for (let item of this.storyList) {
        if (
          item.depth1 == this.depth1Value &&
          item.depth2 == this.depth2Value &&
          item.depth3 != undefined
        ) {
          this.showDepth3List.push(item);
          item.index = index;
          index += 1;
        }
      }
    },
    changeLoadingStatus(status: any) {
      this.$emit("changeLoadingStatus", status);
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    onPositiveClickShowSample() {
      this.pauseAudio();
    },
    onPositiveClickManageCategory() {
      if (
        !this.isDepth3 &&
        !this.isDepth2Confirmed &&
        this.existingCategoryData == undefined
      ) {
        this.alertMessage = "카테고리 이름 중복확인을 눌러주세요";
        this.alertType = "error";
        this.isAlert = true;
        return false;
      }

      if (!this.storyKor || !this.storyEng) {
        this.alertMessage = "한글 이름과 영어 이름을 모두 입력해주세요";
        this.alertType = "error";
        this.isAlert = true;
        return false;
      }
      return new Promise<void>((resolve, reject) => {
        common.asyncFunction(async () => {
          try {
            this.isLoading = true;
            await Promise.all([this.addModifyStory()]);

            this.alertMessage = this.existingCategoryData ? "수정 성공" : "등록 성공";
            this.alertType = "success";
            this.isAlert = true;
          } catch (error) {
            this.alertMessage = this.existingCategoryData ? "수정 실패" : "등록 실패";
            this.alertType = "error";
            this.isAlert = true;
          } finally {
            this.isLoading = false;
            this.resetEtc();
            this.resetSample();
            this.existingCategoryData = undefined;

            resolve();

            this.fetchData();
          }
        });
      });
    },
    onPositiveClickEditOrder() {
      //ifedit was click but not
      if (this.enabledEditCat != null || this.enabledEditCat != undefined) {
        this.alertMessage = "순서 변경 후 '완료'를 눌러주세요.";
        this.alertType = "error";
        this.isAlert = true;
        return false;
      }
      if (!this.areIdsUnique()) {
        this.alertMessage = "순서가 중복되었습니다. 각 항목의 순서를 다시 확인해주세요";
        this.alertType = "error";
        this.isAlert = true;
        return false;
      }
      this.$emit("changeLoadingStatus", true);
      let orderInfoList = [] as any;
      for (let index = 0; index < this.tempShowDepth2List.length; index++) {
        const element = this.tempShowDepth2List[index];
        let orderInfo = {} as any;
        orderInfo.SK = element.SK;
        orderInfo.order = element.order;
        orderInfoList.push(orderInfo);
      }
      common.asyncFunction(async () => {
        let data = {
          story: orderInfoList,
        } as any;

        modifyStoryOrder(data)
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "순서 변경 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "순서 변경 성공";
              this.alertType = "success";
              this.isAlert = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.alertMessage = "순서 변경 실패";
            this.alertType = "error";
            this.isAlert = true;
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
            this.fetchData();
          });
      });
    },
    onNegativeClickEditOrder() {
      this.closeEditOrder();
    },
    closeEditOrder() {
      this.tempShowDepth2List = JSON.parse(JSON.stringify(this.showDepth2List));
    },
    getLastOrder() {
      const highestOrderItem = this.showDepth2List.reduce(
        (max: any, item: any) => {
          const orderValue = item.order !== undefined ? item.order : -Infinity;
          return orderValue > max.order ? item : max;
        },
        { order: -Infinity }
      );

      const highestOrderValue =
        highestOrderItem.order !== undefined ? highestOrderItem.order : null;

      return highestOrderValue;
    },
    addModifyStory(): Promise<void> {
      return new Promise((resolve, reject) => {
        common.asyncFunction(async () => {
          let data = {
            isMandatory: false,
            isUse: this.isDepth3 ? true : this.useCategoryValue,
            storyKor: this.storyKor,
            storyEng: this.storyEng,
            storyEtc: this.newEtcDetails,
          } as any;
          if (!this.isDepth3) {
            data.isFree = this.freeCategoryValue;
          }

          if (this.existingCategoryData == undefined) {
            if (!this.isDepth3) {
              let categoryOrder = this.getLastOrder() + 1;
              data.order = categoryOrder;
            }
            data.depth1 = this.depth1Value;
            data.depth2 = this.isDepth3 ? this.depth2Value : this.newDepth2Value;
            data.isDepth3 = this.isDepth3;

            addStory(data)
              .then(async (result: any) => {
                if (result.data.error) {
                  this.alertMessage = "등록 실패";
                  this.alertType = "error";
                  this.isAlert = true;
                  reject(result.data.error);
                } else {
                  //upload file
                  if (!this.isDepth3) {
                    await this.uploadAllFiles();
                    if (this.addSampleDetails && this.addSampleDetails.info) {
                      await this.modifySample();
                    }
                  }
                  resolve();
                }
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                //this.$emit("changeLoadingStatus", false);
              });
          } else {
            data.id = this.existingCategoryData.SK;
            modifyStory(data)
              .then(async (result: any) => {
                if (result.data.error) {
                  this.alertMessage = "등록 실패";
                  this.alertType = "error";
                  this.isAlert = true;
                  reject(result.data.error);
                } else {
                  if (!this.isDepth3) {
                    await this.uploadAllFiles();
                    if (this.addSampleDetails && this.addSampleDetails.info) {
                      await this.modifySample();
                    }
                  }
                  resolve();
                }
              })
              .catch((error) => {
                reject();
              })
              .finally(() => {
                //this.$emit("changeLoadingStatus", false);
              });
          }
        });
      });
    },

    async uploadAllFiles() {
      const tempFileDetails =
        Object.keys(this.newSampleDetails).length != 0
          ? this.newSampleDetails
          : this.tempSampleDetails;
      const sampleDetails = this.newSampleDetails;

      let data = this.addSampleDetails;

      if (this.existingCategoryData == undefined) {
        data.id = "story#" + this.depth1Value + "_" + this.newDepth2Value;
      } else {
        data.id = this.existingCategoryData.SK;
      }
      let langInfo = {} as any;
      const uploadPromises = Object.keys(tempFileDetails).map(async (key) => {
        let path = this.depth1Value + "/" + this.newDepth2Value + "/" + key;

        let sampleInfo = {} as any;
        langInfo[key] = sampleInfo;

        if (sampleDetails[key]) {
          sampleInfo.description = sampleDetails[key].description;
          let fileInfo = sampleDetails[key].sample1.file;
          let fileExist = fileInfo != undefined;
          if (fileExist) {
            if (sampleDetails[key].sample1.sample1Url) {
              await common.deleteFile(sampleDetails[key].sample1.sample1Url); //이미 스토리지에 있으면 먼저 지우고 다시 울림
            }
            sampleInfo.sample1Url = await common.uploadSample(
              fileInfo,
              fileInfo.name,
              path
            );
          } else {
            sampleInfo.sample1Url = sampleDetails[key].sample1.sample1Url;
          }

          fileInfo = sampleDetails[key].sample2.file;
          fileExist = fileInfo != undefined;
          if (fileExist) {
            if (sampleDetails[key].sample2.sample2Url) {
              await common.deleteFile(sampleDetails[key].sample2.sample2Url); //이미 스토리지에 있으면 먼저 지우고 다시 울림
            }
            sampleInfo.sample2Url = await common.uploadSample(
              fileInfo,
              fileInfo.name,
              path
            );
          } else {
            sampleInfo.sample2Url = sampleDetails[key].sample2.sample2Url;
          }
          data.info = langInfo;
        } else {
          if (tempFileDetails[key].sample1.sample1Url) {
            await common.deleteFile(tempFileDetails[key].sample1.sample1Url); //이미 스토리지에 있으면 먼저 지우고 다시 울림
          }

          if (tempFileDetails[key].sample2.sample2Url) {
            await common.deleteFile(tempFileDetails[key].sample2.sample2Url); //이미 스토리지에 있으면 먼저 지우고 다시 울림
          }
          data.info = {};
        }

        return null;
      });

      return Promise.all(uploadPromises);
    },
    modifySample(): Promise<void> {
      return new Promise((resolve, reject) => {
        common.asyncFunction(async () => {
          modifySample(this.addSampleDetails)
            .then(async (result: any) => {
              if (result.data.error) {
                this.alertMessage = "등록 실패";
                this.alertType = "error";
                this.isAlert = true;
                reject();
              } else {
                resolve();
              }
            })
            .catch((error: any) => {
              console.log(error);
              reject();
            })
            .finally(() => {});
        });
      });
    },
    showEditOrder() {
      this.isShowEditOrder = true;
      this.currOrder = undefined;
      this.enabledEditCat = null;
    },
    showAddCategory1() {
      this.isDepth3 = false;
      this.showManageCategoryDialog();
    },
    showAddCategory2() {
      this.isDepth3 = true;
      this.showManageCategoryDialog();
    },
    showManageCategoryDialog() {
      //this.resetSample();
      this.isLoading = false;
      this.setManageCategoryDialogData();
      this.isShowManageCategoryDialog = true;
    },
    hideAddContent() {
      this.selectedItem = undefined;

      this.fetchData();
    },

    getStorys(): Promise<void> {
      //this.$emit("changeLoadingStatus", true);
      return new Promise((resolve, reject) => {
        common.asyncFunction(async () => {
          getStorys({})
            .then(async (result: any) => {
              this.storyList = result.data.data.Items;
              let depth1Set = new Set();
              let depth2Set = new Set();
              for (let item of this.storyList) {
                if (item.depth1 != undefined) depth1Set.add(item.depth1);
                if (item.depth2 != undefined) depth2Set.add(item.depth2);
                item.rowOnClickEtc = this.showEtcDetail;
                item.rowOnClickSample = this.showSampleDetail;
                item.rowOnClickModify1 = this.setCategoryData1;
                item.rowOnClickModify2 = this.setCategoryData2;
                item.rowOnClickDelete1 = this.confirmDelete1;
                item.rowOnClickDelete2 = this.confirmDelete2;
                item.isUseParse = item.isUse ? "O" : "X";
                item.isFreeParse = item.isFree ? "O" : "X";
              }

              this.depth1 = [];

              let depth1List = Array.from(depth1Set).sort();
              for (let d1 of depth1List) {
                this.depth1.push({
                  key: d1,
                  value: false,
                });
              }

              this.selectSection();

              this.selectCategory1();

              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              // this.$emit("changeLoadingStatus", false);
            });
        });
      });
    },
    getStorySample(id: any) {
      this.sampleObj = {};
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getSample({ id: id })
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              const resultData = result.data.data;
              if (resultData.PK != undefined) {
                this.sampleObj = resultData.info;
              }

              for (let key in this.sampleObj) {
                let item = this.sampleObj[key];
                item.isPlay1 = false;
                item.isPlay2 = false;
              }

              this.isShowSamplePlayDialog = true;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    playStopFile(item: any, sample: any) {
      //this.$emit("changeLoadingStatus", true);

      this.isLoading = true;
      let fileUrl = "";
      let isPlay = true;
      if (sample == constant.sample_type.SAMPLE_1) {
        fileUrl = item.sample1Url;
        isPlay = item.isPlay1;
      } else {
        fileUrl = item.sample2Url;
        isPlay = item.isPlay2;
      }

      if (!isPlay) {
        for (let key in this.sampleObj) {
          let sample = this.sampleObj[key];
          if (sample.isPlay1) sample.isPlay1 = false;
          if (sample.isPlay2) sample.isPlay2 = false;
        }
      }

      common.asyncFunction(async () => {
        this.pauseAudio();

        if (!isPlay) {
          let url = (await common.getFileUrl(fileUrl)) as any;
          if (url.success) {
            this.audio = new Audio(url.data);
            this.audio
              .play()
              .then(() => {
                if (sample == constant.sample_type.SAMPLE_1) {
                  item.isPlay1 = true;
                } else {
                  item.isPlay2 = true;
                }
                this.isLoading = false;
              })
              .catch((error: any) => {
                this.isLoading = false;
                this.alertMessage = error;
                this.alertType = "error";
                this.isAlert = true;
                return;
              });
            this.audio.addEventListener("ended", function () {
              if (sample == constant.sample_type.SAMPLE_1) {
                item.isPlay1 = false;
              } else {
                item.isPlay2 = false;
              }
            });
          } else {
            this.isLoading = false;
            this.alertMessage = url.message._baseMessage;
            this.alertType = "error";
            this.isAlert = true;
            this.isLoading = false;
          }
        } else {
          if (sample == constant.sample_type.SAMPLE_1) {
            item.isPlay1 = false;
          } else {
            item.isPlay2 = false;
          }
          this.isLoading = false;
        }
      });
    },
    checkDuplicate() {
      if (this.isDepth2Confirmed) return;
      if (!this.newDepth2Value) {
        this.alertMessage = "카테고리 이름을 입력해주세요";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }
      this.isCheckingDuplicate = true;
      common.asyncFunction(async () => {
        existStory({ depth1: this.depth1Value, depth2: this.newDepth2Value })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "문제가 생겼습니다.";
              this.alertType = "error";
              this.isAlert = true;
              this.isDepth2Confirmed = false;
            } else {
              const storyExist = result.data.data;
              if (storyExist) {
                this.alertMessage =
                  "이미 등록되어 있는 카테고리입니다. 다른 카테고리를 입력해주세요.";
                this.alertType = "error";
                this.isAlert = true;
                this.isDepth2Confirmed = false;
              } else {
                this.isDepth2Confirmed = true;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isCheckingDuplicate = false;
          });
      });
    },
    addStoryEtcToList() {
      if (this.countryValue && this.storyEtcValue) {
        this.newEtcDetails[this.countryValue] = this.storyEtcValue;
        this.resetEtc();
      } else {
        this.alertMessage = "모든 항목을 입력해야 합니다.";
        this.alertType = "error";
        this.isAlert = true;
      }
    },
    delStoryEtcFromList(key: any) {
      delete this.newEtcDetails[key];
    },
    delStorySampleFromList(key: any) {
      delete this.newSampleDetails[key];
    },
    closeAndAddEtc() {
      this.isShowAddEtcDialog = false;
    },

    closeAndAddSample() {
      if (!this.sampleCountryValue) {
        this.alertMessage = "언어를 선택해주세요.";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }

      let isCustomVoice = false;
      if (this.existingCategoryData) {
        isCustomVoice =
          this.existingCategoryData.depth1 == "S1" &&
          this.existingCategoryData.depth2 == "CU";
      }

      if (!this.existingCategoryData) {
        if (
          !isCustomVoice &&
          this.sample1FileList.length == 0 &&
          this.sample2FileList.length == 0
        ) {
          this.alertMessage = "파일을 선택해야 합니다.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
        if (!isCustomVoice && !this.file1 && !this.file2) {
          this.alertMessage = "파일을 선택해야 합니다.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
      } else {
        let url1 = undefined;
        let url2 = undefined;
        if (Object.keys(this.newSampleDetails).length != 0) {
          if (this.newSampleDetails[this.sampleCountryValue]) {
            url1 = this.newSampleDetails[this.sampleCountryValue]?.sample1?.sample1Url;
            url2 = this.newSampleDetails[this.sampleCountryValue]?.sample2?.sample2Url;
          }
        }
        if (!url1 && !url2) {
          if (
            !isCustomVoice &&
            this.sample1FileList.length == 0 &&
            this.sample2FileList.length == 0
          ) {
            this.alertMessage = "파일을 선택해야 합니다.";
            this.alertType = "error";
            this.isAlert = true;
            return;
          }
          if (!isCustomVoice && !this.file1 && !this.file2) {
            this.alertMessage = "파일을 선택해야 합니다.";
            this.alertType = "error";
            this.isAlert = true;
            return;
          }
        }
      }

      let fileUp1 = undefined;
      let fileUp2 = undefined;
      if (this.file1 != undefined) {
        fileUp1 = this.file1.file;
      }
      if (this.file2 != undefined) {
        fileUp2 = this.file2.file;
      }

      let url1 = undefined;
      let url2 = undefined;
      if (Object.keys(this.newSampleDetails).length != 0) {
        if (this.newSampleDetails[this.sampleCountryValue]) {
          url1 = this.newSampleDetails[this.sampleCountryValue]?.sample1?.sample1Url;
          url2 = this.newSampleDetails[this.sampleCountryValue]?.sample2?.sample2Url;
        }
      }

      let sample1Info = {
        file: fileUp1,
        sample1Url: url1,
      };
      let sample2Info = {
        file: fileUp2,
        sample2Url: url2,
      };
      this.isShowAddSampleDialog = false;

      // this.newSampleDetails[this.sampleCountryValue].description = this.sampleDesc;
      // this.newSampleDetails[this.sampleCountryValue].sample1 = sample1Info;
      // this.newSampleDetails[this.sampleCountryValue].sample2 = sample2Info;
      // this.newSampleDetails[this.sampleCountryValue].needUpload = true;

      this.newSampleDetails[this.sampleCountryValue] = {
        description: this.sampleDesc,
        sample1: sample1Info,
        sample2: sample2Info,
        needUpload: true,
      };
    },
    isCountryRegistered(countryCode: any) {
      if (Object.keys(this.newSampleDetails).length != 0) {
        if (this.newSampleDetails[countryCode]) return true;
      }
      return false;
    },
    createCountryCodeList() {
      this.sampleCountryValues = [];
      if (this.storyKor) {
        this.sampleCountryValues.push({
          label: "한국어",
          value: "ko_KR",
          disabled: this.isCountryRegistered("ko_KR"),
        });
      }

      if (this.storyEng) {
        this.sampleCountryValues.push({
          label: "영어",
          value: "en_EN",
          disabled: this.isCountryRegistered("en_EN"),
        });
      }

      for (const key in this.newEtcDetails) {
        this.sampleCountryValues.push({
          label: this.countryValues.find((item) => item.value === key)?.label ?? "",
          value: key,
          disabled: this.isCountryRegistered(key),
        });
      }

      if (this.sampleCountryValues.length == 0) {
        if (Object.keys(this.newSampleDetails).length == 0) {
          this.alertMessage = "한글이름과 영어이름을 먼저 입력해주세요.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        } else {
          this.alertMessage = "다른 언어를 먼저 추가하거나 등록된 샘플을 수정해주세요";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
      }

      this.isShowAddSampleDialog = true;
    },
    sample1Select(e: any) {
      this.file1 = e.file;
    },
    sample2Select(e: any) {
      this.file2 = e.file;
    },
    resetEtc() {
      this.countryValue = null;
      this.storyEtcValue = "";
      this.editEtcKey = undefined;
    },
    resetSample() {
      this.sampleCountryValue = null;
      this.sampleDesc = "";
      this.file1 = undefined;
      this.file2 = undefined;
      this.sample1FileList = [];
      this.sample2FileList = [];
      this.isEditStorySample = false;
      this.isDisableUpload = false;
      //this.newSampleDetails = {};
    },
    setManageCategoryDialogData() {
      let data = this.existingCategoryData;
      let isDataExist = data != undefined;

      this.newDepth2Value = this.isDepth3
        ? this.depth2Value
        : isDataExist
        ? data.depth2
        : "";
      this.useCategoryValue = isDataExist ? data.isUse : this.useCategory[0].value;
      this.freeCategoryValue = isDataExist ? data.isFree : this.useCategory[1].value;
      this.mandatoryCategoryValue = isDataExist
        ? data.isMandatory
        : this.mandatoryCategory[0].value;
      this.storyKor = isDataExist ? data.storyKor : "";
      this.storyEng = isDataExist ? data.storyEng : "";
      let etcDetails = data?.storyEtc;
      this.newEtcDetails = etcDetails != undefined ? etcDetails : {};
      this.newSampleDetails = {};
      this.isDepth2Confirmed = false;
      this.isCheckingDuplicate = false;
      if (isDataExist) {
        this.isFetching = true;
        common.asyncFunction(async () => {
          getSample({ id: data.SK })
            .then(async (result: any) => {
              if (result.data.error) {
              } else {
                const resultData = result.data.data;
                if (resultData.PK != undefined) {
                  const sampleInfo = resultData.info;
                  for (const key in sampleInfo) {
                    this.newSampleDetails[key] = {
                      description: sampleInfo[key].description ?? "",
                      sample1: { sample1Url: sampleInfo[key].sample1Url ?? undefined },
                      sample2: { sample2Url: sampleInfo[key].sample2Url ?? undefined },
                    };
                  }

                  this.tempSampleDetails = JSON.parse(
                    JSON.stringify(this.newSampleDetails)
                  );
                }
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.isFetching = false;
            });
        });
      }
    },
    editStoryEtcFromList(key: any) {
      if (this.editEtcKey == undefined) {
        this.editEtcKey = key;
      } else {
        if (this.editEtcKey == key) {
          this.editEtcKey = undefined;
        } else {
          this.editEtcKey = key;
        }
      }

      // this.countryValue = key;
      // this.storyEtcValue = this.newEtcDetails[key];
    },
    editStorySample(key: any) {
      this.isEditStorySample = true;
      this.showAddStorySample(true);
      this.sampleCountryValue = key;
      this.sampleDesc = this.newSampleDetails[key].description;
      let sampleObj1 = this.newSampleDetails[key].sample1;
      let sampleObj2 = this.newSampleDetails[key].sample2;

      let sample1 = sampleObj1.file;
      let sample2 = sampleObj2.file;

      if (sample1 != undefined) {
        sample1.id = this.existingCategoryData == undefined ? sample1?.name : "sample1";
        sample1.status = "finished";
        if (this.existingCategoryData == undefined) {
          this.sample1FileList = [sample1];
        }
      }

      if (sample2 != undefined) {
        sample2.id = this.existingCategoryData == undefined ? sample2?.name : "sample2";
        sample2.status = "finished";
        if (this.existingCategoryData == undefined) {
          this.sample2FileList = [sample2];
        }
      }

      if (this.existingCategoryData && this.newSampleDetails[this.sampleCountryValue]) {
        this.isDisableUpload = true;
      }
    },
    closeManageCategory() {
      if (this.isFetching) {
        return false;
      }

      for (let key in this.newEtcDetails) {
        delete this.newEtcDetails[key];
      }

      if (this.existingCategoryData != undefined) {
        this.existingCategoryData = undefined;
      }
    },

    closeShowSample() {
      this.pauseAudio();
    },
    fileReUpload() {
      this.isDisableUpload = false;
    },

    rowProps(row: any) {
      return {
        style: "cursor: pointer;",
        onClick: () => {
          this.showDepth3Table(row);
          this.markRow(row);
          this.sItem = row;
        },
      };
    },

    showDepth3Table(row: any) {
      this.depth2Value = row.depth2;
      this.selectCategory1();
      this.isShowDepth3Table = true;
    },
    markRow(row: any) {
      this.selectedRowIndex = row.order;
    },
    rowClassName(row: any, index: any) {
      return {
        "selected-row": row.order === this.selectedRowIndex,
      };
    },
    onDeletePositiveClick() {
      this.deleteDepth2();
      this.isDeleteConfirm = false;
    },
    onDeleteNegativeClick() {
      this.isDeleteConfirm = false;
    },
    deleteDepth2() {
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        deleteStory({
          id: this.sItem.SK,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "삭제 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              if (!this.isDepth3) {
                getSample({ id: this.sItem.SK })
                  .then(async (result: any) => {
                    if (result.data.error) {
                    } else {
                      const resultData = result.data.data;
                      if (resultData.PK != undefined) {
                        const info = resultData.info;
                        for (let key in info) {
                          let url = info[key].sample1Url;
                          if (url) {
                            common.deleteFile(url);
                          }
                          url = info[key].sample2Url;
                          if (url) {
                            await common.deleteFile(url);
                          }
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              this.alertMessage = "삭제 성공";
              this.alertType = "success";
              this.isAlert = true;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
            this.fetchData();
          });
      });
    },
    onMoveCallback(evt: any, originalEvent: any) {
      const oldOrder = parseInt(originalEvent.fromElement?.parentNode?.id);
      const newOrder = parseInt(originalEvent.toElement?.parentNode?.id);
      const copyShowDepth2List = JSON.parse(JSON.stringify(this.showDepth2List));
      const cat =
        copyShowDepth2List.find((item: any) => item.order === oldOrder) ?? undefined;
      this.currOrder = oldOrder;
      cat.order = newOrder;
      //this.reOrder(cat, { autoOrder: true });
    },
    endDrag(event: any) {
      this.tempShowDepth2List.forEach((item: any, index: any) => {
        item.order = index;
      });

      this.triggerSplashEffect(event);
    },
    triggerSplashEffect(event: any) {
      console.log(event);
      const newIndex = event.oldIndex;
      console.log(newIndex);
      var row = document.getElementById(newIndex);
      if (row != null || row != undefined) {
        row.classList.add("splash-effect");
        row.addEventListener("animationend", () => {
          if (row != null || row != undefined) {
            row.classList.remove("splash-effect");
          }
        });
      }
    },
    enableEditOrder(cat: any) {
      if (this.enabledEditCat != null && this.enabledEditCat == cat.SK) {
        if (this.currOrder != cat.order) {
          console.log(cat.order);

          if (cat.order == null) {
            this.alertMessage = "숫자만 입력 가능";
            this.alertType = "error";
            this.isAlert = true;
            return;
          }
          this.reOrder(cat);
        }

        this.enabledEditCat = null;
        this.currOrder = undefined;
      } else {
        // this.focus(cat.SK);
        this.enabledEditCat = cat.SK;
        this.currOrder = cat.order;
      }
    },
    // focus(sk: any) {
    //   if (this.inputInstRef?.value) {
    //     // Check if inputInstRef.value is an HTMLInputElement or HTMLTextAreaElement
    //     const inputElement = this.inputInstRef.value as
    //       | HTMLInputElement
    //       | HTMLTextAreaElement;
    //     inputElement.focus();
    //   }
    // },
    reOrder(cat: any, { autoOrder }: { autoOrder?: boolean } = { autoOrder: false }) {
      if (cat === undefined) return;
      let newOrder = cat.order;
      console.log(this.tempShowDepth2List);

      for (let index = 0; index < this.tempShowDepth2List.length; index++) {
        const element = this.tempShowDepth2List[index];
        if (element.SK != cat.SK && !autoOrder) {
          if (newOrder > this.currOrder) {
            if (element.order > this.currOrder && element.order <= newOrder) {
              element.order = element.order - 1;
            }
          } else {
            if (element.order >= newOrder && element.order < this.currOrder) {
              element.order = element.order + 1;
            }
          }
        }

        // if (element.SK == cat.SK && autoOrder) {
        //   console.log(element.SK);
        //   console.log(this.currOrder);
        //   console.log("this.currOrder");
        //   console.log("newOrder");
        //   console.log(newOrder);
        //   console.log(element.order);

        //   element.order = 1;
        //   console.log(element);
        // }
      }

      this.tempShowDepth2List.sort((a: any, b: any) => a.order - b.order);
    },

    areIdsUnique() {
      const ids = this.tempShowDepth2List.map((item: any) => item.order);
      const uniqueIds = new Set(ids);

      return uniqueIds.size === this.tempShowDepth2List.length;
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.title_delimiter_line {
  border: lightgrey 1px solid;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Light overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's on top of modal content */
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.flex-col-mid {
  flex-direction: column;
  align-items: start;
}

.no-margin-p {
  margin-block-start: 0;
  margin-block-end: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selected-row td {
  background-color: yellow !important;
}

.dragItem {
  margin-bottom: 10px;
  padding: 5px;
  border: solid 1px gray;
  border-radius: 30pt;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s; /* Smooth transition effect */
  cursor: move !important;
}

.dragItem:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
}

table {
  border-collapse: collapse;
}

table tr {
  height: 30px;
  padding: 10px 0;
  text-align: center;
}

.header-row {
  border-bottom: solid 2px #f0f0f0;
}

.header-row td {
  font-weight: 800;
}

.body-row {
  transition: all 0.3s; /* Smooth transition effect */
  cursor: move !important;
}

.body-row td {
  padding: 10px 0;
}

.body-row:hover {
  background-color: #f0f0f0;
}

.ghost {
  background-color: #18a058;
  color: white;
}

.splash-effect {
  background-color: #18a058; /* Adjust color as needed */
  animation: splash 1s ease-out forwards;
}

/* Splash animation */
@keyframes splash {
  0% {
    background-color: #18a058;
    color: white;
  }
  100% {
    background-color: #18a058;
    color: white;
  }
}
</style>
