<template>
  <div class="main_div">
    <Loading v-show="isLoading" />
    <!-- 상단 해더 -->
    <TopBar></TopBar>
    <div style="min-width: 2000px; width: 100%">
      <SideBar @changeSelectedHomeView="changeSelectedHomeView"></SideBar>
      <!-- 컨텐츠 영역 -->
      <div class="content-wrap">
        <DashBoard
          v-if="selectedHomeViewConstant == homeViewConstant.DashBoard"
          @changeLoadingStatus="changeLoadingStatus"
        ></DashBoard>
        <Content
          v-if="selectedHomeViewConstant == homeViewConstant.Content"
          @changeLoadingStatus="changeLoadingStatus"
        ></Content>
        <Category
          v-if="selectedHomeViewConstant == homeViewConstant.Category"
          @changeLoadingStatus="changeLoadingStatus"
        ></Category>
        <Feedback
          v-if="selectedHomeViewConstant == homeViewConstant.Feedback"
          @changeLoadingStatus="changeLoadingStatus"
        ></Feedback>
        <Member
          v-if="selectedHomeViewConstant == homeViewConstant.Member"
          @changeLoadingStatus="changeLoadingStatus"
        ></Member>
        <Notice
          v-if="selectedHomeViewConstant == homeViewConstant.Notice"
          @changeLoadingStatus="changeLoadingStatus"
        ></Notice>
        <Admin
          v-if="selectedHomeViewConstant == homeViewConstant.Admin"
          @changeLoadingStatus="changeLoadingStatus"
        ></Admin>
        <ElevenLabs
          v-if="selectedHomeViewConstant == homeViewConstant.ElevenLabs"
          @changeLoadingStatus="changeLoadingStatus"
        ></ElevenLabs>
        <PromotionCode
          v-if="selectedHomeViewConstant == homeViewConstant.PromotionCode"
          @changeLoadingStatus="changeLoadingStatus"
        ></PromotionCode>
        <Coupon
          v-if="selectedHomeViewConstant == homeViewConstant.Coupon"
          @changeLoadingStatus="changeLoadingStatus"
        ></Coupon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import * as constant from '@/assets/constant';
import TopBar from "@/components/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
import * as apiService from "@/service/apiService";
import Loading from "@/components/Loading.vue";
import DashBoard from "@/views/dashboard/DashBoard.vue";
import Content from "@/views/content/Content.vue";
import Category from "@/views/category/Category.vue";
import Feedback from "@/views/feedback/Feedback.vue";
import Member from "@/views/member/Member.vue";
import Admin from "@/views/admin/Admin.vue";
import Notice from "@/views/notice/Notice.vue";
import ElevenLabs from "@/views/elevenLabs/ElevenLabs.vue";
import PromotionCode from "@/views/promotionCode/PromotionCode.vue";
import Coupon from "@/views/coupon/Coupon.vue";
import * as constant from "@/assets/constant";
import * as common from "@/assets/common/common";
import { getCallable, firebaseAuth } from "@/config/env";
import * as auth from "@/assets/common/auth";
const getMemberByAdmin = getCallable("getMemberByAdmin");

export default defineComponent({
  name: "Home",
  components: {
    TopBar,
    SideBar,
    Loading,
    DashBoard,
    Content,
    Category,
    Feedback,
    Member,
    Notice,
    Admin,
    ElevenLabs,
    PromotionCode,
    Coupon,
  },
  data() {
    return {
      isLogin: false,
      isLoading: false,
      userInfo: {} as any,
      homeViewConstant: constant.homeView,
      selectedHomeViewConstant: "",
    };
  },
  async mounted() {
    // this.$emit('changeLoadingStatus', true);
    this.isLogin = true;
    this.changeSelectedHomeView(this.homeViewConstant.DashBoard);
  },
  methods: {
    changeLoadingStatus(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    async logout() {
      await auth.logout();
      (this as any).$router.push("/");
    },
    changeSelectedHomeView(homeViewConstant: any) {
      console.log("try changeSelectedHomeView");
      this.changeLoadingStatus(true);
      // this.$emit('changeLoadingStatus', true);
      common.asyncFunction(async () => {
        await firebaseAuth.authStateReady();
        let currentUser = await auth.getCurrentUser();
        if (currentUser == null) {
          await this.logout();
          return;
        }
        await getMemberByAdmin({
          uid: currentUser.uid,
        })
          .then(async (result: any) => {
            if (result.data.data.Items.length < 1) {
              await this.logout();
              return;
            } else {
              let user = result.data.data.Items[0];
              if (user.isLock == true) {
                await this.logout();
              }
              (this as any).$i18n.locale = user.contentLanguage;

              this.$store.commit("setUser", user);
              // console.log(this.$store.getters.getUser);
              this.selectedHomeViewConstant = homeViewConstant;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
        this.$emit("changeLoadingStatus", false);
      });
    },
  },
});
</script>

<style scoped>
.content-wrap {
  float: left;
  background-color: white;
  display: flex;
}
</style>
